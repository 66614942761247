<template>
  <div class="Content" ref="printContent">
    <pdf id="myIframe" ref="pdfDom" :src="pdfSrc" :page="currentPage" @loaded="loadPdfHandler"></pdf>
    <div style="text-align: center;">
      <el-button size="small" type="primary" @click="changePdfPage(0)" :class="{ grey: currentPage == 1 }">上一页</el-button>
      {{ currentPage }} / {{ pageCount }}
      <el-button size="small" type="primary" @click="changePdfPage(1)" :class="{ grey: currentPage == pageCount }">下一页</el-button>
    </div>
  </div>
</template>
<script>
import pdf from 'vue-pdf'
export default {
  components: {
    pdf
  },
  props: ['pdfSrc'],
  data() {
    return {
      pageCount: 14, // pdf文件总页数
      currentPage: 1 // pdf文件页码
    }
  },
  watch: {},
  created() {},
  methods: {
    // 改变PDF页码,val传过来区分上一页下一页的值,0上一页,1下一页
    changePdfPage(val) {
      if (val === 0 && this.currentPage > 1) {
        this.currentPage--
      }
      if (val === 1 && this.currentPage < this.pageCount) {
        this.currentPage++
      }
    },
    // pdf加载时
    loadPdfHandler() {
      this.currentPage = 1 // 加载的时候先加载第一页
    }
  }
}
</script>
<style scoped></style>
