<template>
  <div class="UserAggrement">
    <h3>个人信息授权书</h3>
    <div class="content">
      <div class="line bold">【重要提示】</div>
      <div class="line bold">尊敬的客户：为了保障您(在正文中称为"本人")的合法权益，请您务必认真阅读、充分理解本授权书所有条款（尤其是黑体字加粗条款），特别是您在授权书范围内的权利和义务。您的登录、勾选、点击或 确认等行为均被视为您已阅读并同意本授权书。若有问题，您可以通过本授权书或【"巨悠租"平台】("租赁平台")提供的联系方式联系被授权人。</div>
      <div class="line">【上海高瓴盛科技有限公司】(以下称为"被授权人")：</div>
      <div class="line bold">一 在签署本授权书前，被授权人已告知本人如下信息：</div>
      <div class="line indent-1">1、被授权人基本信息如下：</div>
      <div class="line indent-2">（1）名称：【上海高瓴盛科技有限公司】；</div>
      <div class="line indent-2">（2）联系方式：【15021648934】</div>
      <div class="line indent-1">2、被授权人因合并、分立、解散、被宣告破产等原因需要转移本人个人信息的，被授权人将告知本人接收方的名称和联系方式。</div>
      <div class="line indent-1">3、本授权书所述个人信息的处理目的、处理方式、处理个人信息的种类：与本授权书所述的一致。</div>
      <div class="line indent-1">4、本授权书所述个人信息保存期限：自业务关系终止之日起不少于三年，法律、法规、监管政策对保存期限另有规定的，应按规定更改保存期限。</div>
      <div class="line indent-1">5、本授权书记载的被授权人名称、联系方式；个人信息处理目的、处理方式、处理的个人信息种类、保存期限；本人行使法律、法规、监管政策规定的权利的方式和程序；以及法律、法规、监管政策规定应当告知的其他事项有变更的，被授权人应当将变更部分告知本人。</div>
      <div class="line indent-1">6、被授权人对本人的个人信息处理活动负责，并采取必要措施保障所处理的个人信息的安全。除非本人同意，或者法律、法规、监管政策另有规定的，被授权人不得提供或公开本人的个人信息。</div>
      <div class="line indent-1">7、本人可以通过本授权书和租赁平台提供的联系方式联系被授权人。</div>
      <div class="line indent-1">8、满足本授权书所述条件的，本人可以通过租赁平台撤回个人信息的同意，若租赁平台不适用的，本人可以通过本授权书提供的联系方式联系被授权人撤回同意。</div>

      <div class="line title">二 本授权书所述的个人信息种类包括：</div>
      <div class="line indent-1 bold">1、本人姓名；</div>
      <div class="line indent-1 bold">2、本人联系方式；</div>
      <div class="line indent-1 bold">3、收货信息及相关物流信息；</div>
      <div class="line indent-1 bold">4、身份证件种类及其编号；</div>
      <div class="line indent-1 bold">5、相关投保信息；</div>
      <div class="line indent-1 bold">6、因履行相关业务合同而产生的个人信息；</div>
      <div class="line indent-1 bold">7、其他已在相关业务合同中显示的个人信息。</div>

      <div class="line title">三 本授权书所述的个人信息的目的包括：</div>
      <div class="line indent-1">
        1、审核本人(本人配偶、共同借款人、共同借款人的配偶)
        <b class="textbold">租赁业务的办理</b>
        ；
      </div>
      <div class="line indent-1">2、对前述业务进行风险管理；</div>
      <div class="line indent-1">3、相关业务合同的履行；</div>
      <div class="line indent-1">4、审核本人个人征信异议申请；</div>
      <div class="line indent-1">5、依法向国家有权机构提供；</div>
      <div class="line indent-1">6、本人向被授权人申请办理的其他合法经营范围内的业务。</div>

      <div class="line title">四 本人授权：</div>
      <div class="line indent-1 bold">1、被授权人搜集、存储、使用、加工、传输本人个人信息，但应当限于本授权书所述的个人信息的目的。</div>
      <div class="line indent-1 bold">2、被授权人按照国家相关规定采集并向金融信用信息基础数据库及其他依法成立的征信机构提供的有关本人个人信息和包括信贷信息在内的信用信息（包含本人因未及时履行合同义务产生的不良信息，下同）。</div>
      <div class="line indent-1 bold">3、被授权人根据国家有关规定办理涉及本人的业务时，有权向金融信用信息基础数据库及其他依法成立的征信机构查询、打印、复制、保存本人的信用信息。</div>

      <div class="line title">五 本人同意：</div>
      <div class="line indent-1 bold">1、依法成立的征信机构可向被授权人和有关部门、单位（包括但不限于公安、司法、教育、全国公民身份证号码查询服务中心、通信运营商、社保、公积金、税务、民政、物流、电子商务平台、互联网平台、行业协会等合法留存本人信息的第三方机构等）采集、查询或验证本人信息，并可将采集或查询到的本人信息在本授权书第二条所列的情形下向被授权人提供。</div>
      <div class="line indent-1 bold">
        2、被授权人向依法成立的第三方风控服务商提供因本业务产生的本人（包括但不限于姓名、身份证、手机号、在相关业务中产生的行为、授信及不良信息），同意被授权人自行或者委托第三方进行催收，但法律、法规、监管政策禁止的除外。依法成立的第三方风控服务商查询或核验（含短信与电话等方式验证）、收集、保存、处理、共享、使用（含合法业务应用）本人相关数据，出具相关报告，且可以不再另行告知本人，但法律、法规、监管政策禁止的除外。
        <span class="textnormal">第三方风控服务商必须承担与被授权人一致保护本人个人信息的义务。</span>
      </div>

      <div class="line title">六 如果被授权人超出本授权范围进行数据报送和查询使用，则被授权人应承担与此有关的法律责任。</div>

      <div class="line title">七 本人确认，相关业务合同中的【通知与送达】条款适用于本授权书，相关业务合同中本人的联系方式在本授权书中同样有效。</div>
      <div class="line title bold">八 保存期限届期后，被授权人可以删除本人个人信息。</div>
      <div class="line title bold">九 若本人在被授权人业务未获批准办理，本人接受被授权人文件管理要求将本人授权书及本人信用报告等资料留在被授权人留存，无须退回本人，但保存期限届期后，本人有权要求被授权人删除。</div>
      <div class="line title bold">十 被授权人因合并、分立、解散、被宣告破产等原因，或者因为出让相关业务合同项下债权和\或债务等原因，需要转移个人信息的，本人同意该等转移，且转移后的信息处理者有权根据本授权书继续获得授权。</div>
      <div class="line title bold">十一 因业务需要或法律法规有所规定，致使本授权书所述个人信息处理目的、处理方式、处理的个人信息种类发生变更的，被授权人有权催告本人在7个自然日内作出同意或不同意的表示，若逾期不作表示的，视为同意。</div>
      <div class="line title bold">十二 因业务需要或法律法规有所规定，致使本授权书所述保存期限，本人行使法律法规规定的权利的方式和程序，以及法律、行政法规规定应当告知的其他事项有变更的，本人同意被授权人在法律法规允许的范围内变更。</div>

      <div class="line title">十三 本人有权撤回对个人信息处理的同意，但如下个人信息除外：</div>
      <div class="line indent-1">1、属于不需要基于本人同意，被授权人就可以处理的；</div>
      <div class="line indent-1">2、属于提供产品或服务所必需的。</div>

      <div class="line title bold">十四 本人撤回对个人信息处理的同意不应当影响被授权人根据相关业务合同或其他依据向本人主张债权，也不影响撤回前基于本人的个人同意已进行的个人信息处理活动的效力。</div>

      <div class="line title">十五 即使本授权书系相关业务合同的附件、从合同、子合同，或补充协议等的，本授权书的成立、生效、解除、终止、撤销、撤回、无效等的效力不受相关业务合同效力的影响。</div>
      <div class="line title">十六 因本授权书引起的争议，应由被授权人所在地有管辖权的法院管辖，但若本授权书系相关业务合同的附件、从合同、子合同，或补充协议等的，以相关业务合同确定管辖。</div>
      <div class="line title">十七 本授权书内容与相关业务的合同条款不一致的，无论相关合同在本授权书之前或之后签署，均应以本授权书的内容为准，但相关合同条款明确约定是针对本授权书内容所做修订的除外。</div>

      <div class="line">本人声明：本人已仔细阅读上述所有条款，并已特别注意字体加黑的内容。被授权人已应本人要求对相关条款予以明确说明。本人对所有条款的含义及相应的法律后果已全部知晓并充分理解，本人自愿作出上述授权、承诺和声明。</div>
      <div class="line bold">本人在此确认，本授权书可通过电子数据形式呈现，本人对相关互联网页面或电子数据的同意、确认操作即视为合法有效的签署，线上签署后本授权书立即成立且生效。</div>

      <div class="last-foot">特此授权！</div>
      <div class="last-foot">本人（签名）：</div>
      <div class="last-foot">联系电话：</div>
      <div class="last-foot">证件名称： 身份证</div>
      <div class="last-foot">证件号码：</div>
      <div class="last-foot">签署日期：</div>
    </div>
  </div>
</template>
<script>
export default {
  components: {
  },
  props: [],
  data() {
    return {
    
    }
  },
  watch: {},
  created() {},
  methods: {
    
  }
}
</script>
<style scoped>
.UserAggrement{ padding: 50px 100px;}
.UserAggrement h3{text-align: center; margin-bottom: 10px; font-size: 24px;}
.UserAggrement .bold{ font-weight: bold;}
.UserAggrement .line{ display: block; font-size: 18px; line-height:40px; text-align: justify;}

.UserAggrement .indent-1{text-indent: 1em;}
.UserAggrement .indent-2{text-indent: 2em;}
.UserAggrement .last-foot{ padding-left:60% ; line-height: 30px;}
</style>
